/*
---------------------------------------------------------------------
| Bootstrap Default nav
|--------------------------------------------------------------------
*/
.navbar {
  margin-bottom: 0;
  .brand img {
    padding-top: 25px;
  }
  h1,.nav-title{
    font-size: 18px;
    margin-top: 12px;
  }
  li a {
    color: $default-text-color;
    &:hover,
    &:focus {
      opacity: 0.8;
      text-decoration: none;
      outline: medium none;
      color: $brand-primary;
    }
  }
  .nav-btn {
    border-radius: 3px;
    padding: 8px 20px !important;
  }
  .dropdown {
    .dropdown-item {
      padding: 0.25rem 1.0rem;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .icon {
    padding-right: 5px;
  }
}
.responsive-tab{
  .dropdown-menu{
    box-shadow:$shadow2;
    a, i{
      color: $default-text-color !important;
    }
  }
}
.dropdown-menu{
  box-shadow: 0 0 6px 0 rgba(0,0,0,.12);
  padding: 7px 0;
  min-width: 185px;
  line-height: 18px;
  border-radius: 0;
  border: none;
  .dropdown-item {
    font-weight: 400;
    color:$default-text-color;
    font-size:13px;
    padding: 8px 20px;
    &:hover {
      background-color: $brand-bg;
    }
  }
}
.navbar-expand {
  padding: 0 1rem;
  .navbar-nav {
    .nav-link {
      padding: 1rem 0.7rem;
    }
  }
}

//Nav Amdin Lte
.custom-dropdown
{
  .dropdown-menu {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    border: none;
    z-index: 9999;
    border-radius:3px;
    > {
      li > a {

        > {
          .glyphicon, .fa, .icon {
            margin-right: 10px;
          }
        }
        &:hover {
          background-color: $brand-bg;
        }
      }
      .divider {
        background-color: $brand-border;
      }
    }
  }
}

.navbar-nav > {
  .notifications-menu > .dropdown-menu, .messages-menu > .dropdown-menu, .tasks-menu > .dropdown-menu {
    /*! width:280px; */
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
  }
  .notifications-menu > .dropdown-menu > li, .messages-menu > .dropdown-menu > li, .tasks-menu > .dropdown-menu > li {
    position: relative;

  }
  .notifications-menu > .dropdown-menu > li.header, .messages-menu > .dropdown-menu > li.header, .tasks-menu > .dropdown-menu > li.header {
    border-radius: 3px 3px 0 0;
    padding: 7px 10px;
    border-bottom: 1px solid  $brand-border;
    font-size: 12px;
    color: $default-text-color;
  }

  .notifications-menu > .dropdown-menu > li.footer > a:hover, .messages-menu > .dropdown-menu > li.footer > a:hover, .tasks-menu > .dropdown-menu > li.footer > a:hover {
    text-decoration: none;
    font-weight: normal;
background: none;
  }
  .notifications-menu > .dropdown-menu > li .menu, .messages-menu > .dropdown-menu > li .menu, .tasks-menu > .dropdown-menu > li .menu {
    max-height: 300px;
    margin: 0;
    list-style: none;
    overflow-x: hidden;
  }
  .notifications-menu > .dropdown-menu > li .menu > li > a, .messages-menu > .dropdown-menu > li .menu > li > a, .tasks-menu > .dropdown-menu > li .menu > li > a {
    display: block;
    white-space: nowrap;
    border-bottom: 1px solid #f4f4f4;
  }
  .notifications-menu > .dropdown-menu > li .menu > li > a:hover, .messages-menu > .dropdown-menu > li .menu > li > a:hover, .tasks-menu > .dropdown-menu > li .menu > li > a:hover {
    text-decoration: none;
  }
  .notifications-menu > .dropdown-menu > li .menu > li > a {
    color: #444444;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    > {
      .glyphicon, .fa, .ion {
        width: 20px;
      }
    }
  }
  .messages-menu > .dropdown-menu > li .menu > li > a {
    margin: 0;
    padding: 15px 10px;
    > {
      div > img {
        margin: auto 10px auto auto;
      }
      h4 {
        padding: 0;
        margin: 0 0 0 40px;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        > small {
          color: #999999;
          font-size: 10px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      p {
        margin: 0 0 0 40px;
        font-size: 12px;
        line-height: 1.5;
      }
    }
    &:before {
      content: " ";
      display: table;
    }
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }
  .tasks-menu > .dropdown-menu > li .menu > li > a {
    padding: 10px;
    > {
      h3 {
        font-size: 14px;
        padding: 0;
        margin: 0 0 10px 0;
        color: #666666;
      }
      .progress {
        padding: 0;
        margin: 0;
      }
    }
  }
  .user-menu {
    > .dropdown-menu {
      width: 280px;

      > {

        .user-footer {
          background-color: #f9f9f9;
          padding: 10px;
          &:before {
            content: " ";
            display: table;
          }
          &:after {
            content: " ";
            display: table;
            clear: both;
          }
          .btn-default {
            color: #666666;
          }
        }
      }
    }
    .user-image {
      float: left;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: -2px;
    }
  }
}



@media (max-width: 767px) {
  .navbar-nav > .user-menu .user-image {
    float: none;
    margin-right: 0;
    margin-top: -8px;
    line-height: 10px;
  }
}

.open:not(.dropup) > .animated-dropdown-menu {
  backface-visibility: visible !important;
  -webkit-animation: flipInX .7s both;
  -o-animation: flipInX .7s both;
  animation: flipInX 0.7s both;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}


@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    -webkit-transform: perspective(400px);
  }
}
.navbar-custom-menu {
  .nav-link {
    position: relative;
    @include desktop {
      font-size: 18px;
      [class^="icon-"], [class*=" icon-"] {
        font-size: 22px;
      }
    }
  }
}
.navbar-custom-menu > .navbar-nav > li {
  position: relative;
  > .dropdown-menu {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
    > li {
      position: static;
      > .dropdown-menu {
        position: absolute;
        right: 5%;
        left: auto;
      }
    }
  }
}

//Material Nav
.navbar{
  .nav-material > li > a::after{
    bottom: -10px;
    left: -1px;
  }
}

.brand-wrapper{
  width: 270px;
  height: 60px;
}