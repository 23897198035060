.social {
  list-style: none;
  li {
    padding: 5px 0px;
    a {
      display: inline-block;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      border-radius: 100%;
      color: #fff;
      margin-right: 5px;
      &:hover{
        box-shadow: $hover-shadow;
      }
  
    }
    i {
      padding: 0;
    }
  }
  &.square {
    li {
      border-radius: 0;
    }
  }
  &.rounded {
    li {
      border-radius: 10px;
    }
  }

}

.facebook {
  background: #507cbe;
}
.twitter {
  background: #63cdf1;
}
.gplus {
  background: #f16261;
}
.instagram {
  background: #444444;
}
.youtube {
  background: #CA3737;
}
.linkedin {
  background: #90cadd;
}
.dribbble {
  background: #d97aa6;
}
.behance {
  background: #0785f3;
}