.timeline {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: $brand-border;
    left: 31px;
    margin: 0;
    border-radius: 2px;
  }
  > {
    li {
      position: relative;
      margin-right: 10px;
      margin-bottom: 15px;
      &:before {
        content: " ";
        display: table;
      }
      &:after {
        content: " ";
        display: table;
        clear: both;
      }
      > {
        .timeline-item {
          margin-left: 60px;
          margin-right: 15px;
      }
        .fa, .glyphicon, .ion, .icon {
          width: 30px;
          height: 30px;
          font-size: 12px;
          line-height: 30px;
          position: absolute;
          background-color: #d2d6de;
          border-radius: 3px;
          text-align: center;
          left: 16px;
          top: 8px;
          color: #fff;
        }
      }
    }

  }
}

.timeline-inverse > li > .timeline-item {
  background: #f0f0f0;
  border: 1px solid #ddd;
  box-shadow: none;
  > .timeline-header {
    border-bottom-color: #ddd;
  }
}