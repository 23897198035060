.has-right-sidebar{
  margin-right: $control-sidebar-width;
}
.sidebar-header{
  padding: 20px 20px 6px 20px;
  border-bottom: 1px solid $brand-border;
}
@media (min-width: 1025px) {
  .sidebar-collapse {
    .main-sidebar,
    .left-side {

    }
  }
}

/*
 * Component: Control sidebar. By default, this is the right sidebar.
 */
// The sidebar's background control class
// This is a hack to make the background visible while scrolling
.control-sidebar-bg {
  position: absolute;
  z-index: 1000;
  bottom: 0;
}

// Transitions
.control-sidebar-bg,
.control-sidebar {
  top: 0;
  right:-350px;
  width: $control-sidebar-width;
  -webkit-transition: right .3s ease-in-out;
  -o-transition: right .3s ease-in-out;
  transition: right .3s ease-in-out;
}



// The sidebar
.control-sidebar {
  position: absolute;
  z-index: 10100;

  // Fix position after header collapse
  //@media (max-width: $screen-sm) {
  //  padding-top: $navbar-height + 50;
  //}
  // Tab panes
  > .tab-content {
    padding: 10px 15px;
  }
  // Open state with slide over content effect
  &.control-sidebar-open {
    &,
    + .control-sidebar-bg {
      right: 0;
    }
  }
}

// Open without slide over content
.control-sidebar-open {
  .control-sidebar-bg,
  .control-sidebar {
    right: 0;
  }
  //@media (min-width: $screen-sm) {
  //  .content-wrapper,
  //  .right-side,
  //  .main-footer {
  //    margin-right: $control-sidebar-width;
  //  }
  //}
}

// Fixed Layout
.fixed-sidebar {
  .control-sidebar {
    position: fixed;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
  }
}



// Control sidebar tabs
.nav-tabs.control-sidebar-tabs {
  > li {
    &:first-of-type > a {
      &,
      &:hover,
      &:focus {
        border-left-width: 0;
      }
    }
    > a {
    //  @include border-radius(0);

      // Hover and active states
      &,
      &:hover {
        border-top: none;
        border-right: none;
        border-left: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
      .icon {
        font-size: 16px;
      }
    }
    // Active state
    &.active {
      > a {
        &,
        &:hover,
        &:focus,
        &:active {
          border-top: none;
          border-right: none;
          border-bottom: none;
        }
      }
    }
  }
  // Remove responsiveness on small screens
  //@media (max-width: $screen-sm) {
  //  display: table;
  //  > li {
  //    display: table-cell;
  //  }
  //}
}

// Headings in the sidebar content
.control-sidebar-heading {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
}

// Subheadings
.control-sidebar-subheading {
  display: block;
  font-weight: 400;
  font-size: 14px;
}

// Control Sidebar Menu
.control-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  > li > a {
    //@include clearfix();
    display: block;
    padding: 10px 15px;
    > .control-sidebar-subheading {
      margin-top: 0;
    }
  }
  .menu-icon {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
  }
  .menu-info {
    margin-left: 45px;
    margin-top: 3px;
    > .control-sidebar-subheading {
      margin: 0;
    }
    > p {
      margin: 0;
      font-size: 11px;
    }
  }
  .progress {
    margin: 0;
  }
}

// Dark skin
.control-sidebar-dark {
  color: $sidebar-dark-color;
  //  Background
  &,
  + .control-sidebar-bg {
    background: $sidebar-dark-bg;
  }
  //  Sidebar tabs
  .nav-tabs.control-sidebar-tabs {
  //  border-bottom: darken($sidebar-dark-bg, 3%);
    > li {
      > a {
     //   background: darken($sidebar-dark-bg, 5%);
      //  color: $sidebar-dark-color;
        // Hover and active states
        &,
        &:hover,
        &:focus {
       //   border-left-color: darken($sidebar-dark-bg, 7%);
       //   border-bottom-color: darken($sidebar-dark-bg, 7%);
        }
        &:hover,
        &:focus,
        &:active {
         // background: darken($sidebar-dark-bg, 3%);
        }
        &:hover {
          color: #fff;
        }
      }
      // Active state
      &.active {
        > a {
          &,
          &:hover,
          &:focus,
          &:active {
         //   background: $sidebar-dark-bg;
            color: #fff;
          }
        }
      }
    }
  }
  // Heading & subheading
  .control-sidebar-heading,
  .control-sidebar-subheading {
    color: #fff;
  }
  // Sidebar list
  .control-sidebar-menu {
    > li {
      > a {
        &:hover {
        //  background: $sidebar-dark-hover-bg;
        }
        .menu-info {
          > p {
          //  color: $sidebar-dark-color;
          }
        }
      }
    }
  }
}

// Light skin
.control-sidebar-light {
 // color: lighten($sidebar-light-color, 10%);
  //  Background
  &,
  + .control-sidebar-bg {
   // background: $sidebar-light-bg;
   // border-left: 1px solid $gray-lte;
  }
  //  Sidebar tabs
  .nav-tabs.control-sidebar-tabs {
   // border-bottom: $gray-lte;
    > li {
      > a {
      //  background: darken($sidebar-light-bg, 5%);
     //   color: $sidebar-light-color;
        // Hover and active states
        &,
        &:hover,
        &:focus {
        //  border-left-color: $gray-lte;
        //  border-bottom-color: $gray-lte;
        }
        &:hover,
        &:focus,
        &:active {
        //  background: darken($sidebar-light-bg, 3%);
        }
      }
      // Active state
      &.active {
        > a {
          &,
          &:hover,
          &:focus,
          &:active {
       //     background: $sidebar-light-bg;
            color: #111;
          }
        }
      }
    }
  }
  // Heading & subheading
  .control-sidebar-heading,
  .control-sidebar-subheading {
    color: #111;
  }
  // Sidebar list
  .control-sidebar-menu {
    margin-left: -14px;
    > li {
      > a {
        &:hover {
        //  background: $sidebar-light-hover-bg;
        }
        .menu-info {
          > p {
        //    color: lighten($sidebar-light-color, 10%);
          }
        }
      }
    }
  }
}
