//
//.dropdown-menu{
//  border-radius: 2px;
//  border: none;
//  a{
//    padding-top: 12px;
//    padding-bottom: 12px;
//    font-size: 14px;
//    color: #86939e;
//  }
//}



//tables

.table th, .table td {
  vertical-align: middle;
}