
.input-group-addon {
  min-height: 3rem;
  border-color: $brand-border;
  background: $brand-bg;
  padding: 0.7rem 1rem;

}
.form-control {
  border: 1px solid $brand-border;
  box-shadow: none;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  font-weight: 300;

  &:focus {
    box-shadow: none;
    border-color: #1BB1F5;
  }
}
.form-group {
  position: relative;
  &.has-icon {
    i {
    font-size: 14px;
    position: absolute;
      top: 25px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 20px;
    &:after {
      content: "";
      height: 30px;
      width: 1px;
      border-right: solid 1px $brand-border2;
      position: absolute;
      top: 50%;
      left: 30px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
    input {
      padding-left: 68px;
    }
  }
  &.has-right-icon {
    i{
      position: absolute;
      right: 1.0rem;
      top: 0.7rem;
    }
  }
  &.input-group-sm{
      i {
        top: 0.5rem !important;
      }
  }
}
.has-warning .form-control {
  border-color: orange;
}
form {
  label {
    margin-bottom: 5px;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.9;
  }
}
.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon {
  border: 2px solid $brand-border;
  border-radius: 5px;
  color: #43484d;
  font-size: 14px;
  height: 50px;
  padding: 15px;
  width: 100%;
  &:focus {
    border-color: $brand-primary !important;
  }
}
textarea {
  &.form-control-lg {
    min-height: 100px;
    padding: 0 .85rem;
  }
}
input[type="checkbox"], input[type="radio"] {
  margin-right: 10px !important;
}
.btn {
  border-radius: 3px;
  font-weight: 300;
  .icon{
    padding-right: 15px;
  }
  &:hover{
    border: 1px solid transparent;
  }
  &.btn-sm{
    font-size: 0.8rem;
    padding: 0.35rem 0.6rem;
  }
}

.btn-default {
  border: 1px solid $brand-border2;
  &:hover{
    background: $brand-primary;
    color: $white;
    border: 1px solid $brand-primary;
    box-shadow: none;
  }
}
.btn-fixed-top-left {
  position: fixed;
  left: 52px;
  top: 66px;
  z-index: 99;
}

@include paperBtn('primary', $brand-primary);
@include paperBtn('danger', $brand-danger);
@include paperBtn('warning', $brand-warning);
@include paperBtn('success', $brand-success);
.badge-success, .badge-warning {
  color: #fff;
}

.btn-social{
  color: #fff;
  &:hover, &:focus {
    color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
  }
}

.btn-big {
  padding: 15px 40px !important;;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400 !important;
  &:hover {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
  }
  @include tablet-and-phone {
    font-size: 14px;
  }
}
.btn-img {
  img {
    &:hover {
      transition: box-shadow 500ms ease-out 0.05s;
      background: transparent;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
    }
  }
}
.btn-line {
  display: inline-block;
  color: #fff;
  padding: 10px 22px;
  line-height: 1;
  border: 1px solid #fff;
  border-radius: 0;
  text-align: center;
  font-weight: 100;
  margin: 4px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  background: transparent;
  &:hover,&:focus {
    transition: box-shadow 500ms ease-out 0.05s;
    opacity: 0.8;
    color: $white;
    border: 1px solid ;
  }
}
.btn-xs {
  padding: 0.2rem .5rem;
  font-size: .700rem;
}
.btn-lg {
  border-radius: 3px;
  font-size: 16px;
}
.btn-xl{
  padding: 13px 25px;
  font-size: 16px;
}
.btn-info{
  color: #fff;
}
a.btn-fab{
  line-height: 40px;
}
.btn-fab {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  padding:0;
  color: #fff;
  i{
    color: #fff;
  }
  &:hover,&:active,&:focus {
    text-decoration: none;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26) !important;
    border:none;
  }
  i {
    font-size: 24px;
    vertical-align: middle;
  }
  &.btn-fab-md {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  &.btn-fab-sm{
    width: 30px;
    height: 30px;
    line-height: 30px;
    i{
      font-size: 12px;
    }
  }
}

.fab-right-bottom{
  right: 40px;
  bottom: -16px;
  z-index: 1;
}
.fab-left-bottom{
  left: 40px;
  bottom: -16px;
  z-index: 1;
}
.fab-right-bottom-fixed {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 1;
}
.fab-top {
  top: -15px;
}
.paper-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  &.active i, &.dl-active i {
    &::before, &::after {
      background: $brand-primary;
    }
  }
  &:hover, &:focus, &:active {
    outline: none;
    border-bottom: none !important;
    opacity: 0.8;
  }
  i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 2px;
    color: $brand-primary;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: $brand-primary;
    transition: all .2s ease-out;
    &::before, &::after {
      content: '';
      width: 25px;
      height: 2px;
      background: $brand-primary;
      position: absolute;
      left: 0;
      transition: all .2s ease-out;
    }
  }
  &.paper-nav-white {
    > i {
      color: $white;
      background: $white;
      &::before, &::after {
        background: $white;
      }
    }
  }
  &.left{
    left:0;
    right: auto;
  }
}
.paper-nav-toggle i::before {
  top: -7px;
}

.paper-nav-toggle i::after {
  bottom: -7px;
}

.paper-nav-toggle:hover i::before {
  top: -10px;
}

.paper-nav-toggle:hover i::after {
  bottom: -10px;
}

.paper-nav-toggle.active i, .paper-nav-toggle.dl-active i {
  background: transparent;

}

.paper-nav-toggle.active i::before, .paper-nav-toggle.dl-active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.paper-nav-toggle.active i::after, .paper-nav-toggle.dl-active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.paper-nav-toggle {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: block;
  // background: #f86942;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
  text-align: center;

  @include tablet-and-phone {
    position: absolute;
    display: block;
  }
  .left {
    left: 15px;
  }
}
/* Blink for Webkit and others
(Chrome, Safari, Firefox, IE, ...)
*/

@-webkit-keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.1;}
}
.blink{
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
  -webkit-animation-direction: alternate;
}

.glow {
  color: #444;
  text-shadow:
          1px 0px 1px #ccc, 0px 1px 1px #eee,
          2px 1px 1px #ccc, 1px 2px 1px #eee,
          3px 2px 1px #ccc, 2px 3px 1px #eee,
          4px 3px 1px #ccc, 3px 4px 1px #eee,
          5px 4px 1px #ccc, 4px 5px 1px #eee,
          6px 5px 1px #ccc, 5px 6px 1px #eee,
          7px 6px 1px #ccc;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}.custom-control-label::after,.custom-control-label::before {
   top: -0.6rem !important;
 }