
.easy-pie-chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  text-align: center;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  .percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
  }
  &.easy-pie-chart-lg{
    width: 150px;
    height: 150px;
    .percent {
      line-height: 150px;
    }
  }
  &.easy-pie-chart-sm{
      width: 60px;
      height: 60px;
      .percent {
        line-height: 60px;
      }
    }
  &.easy-pie-chart-xs{
    width: 40px;
    height: 40px;
    .percent {
      line-height: 40px;
    }
  }
}
