// colors
$main-text: #7f8c97; // main text
$light-text: #acb7c0; // anchor tags
$background: #e9f0f5; // body background color
$dark-heading-color: #606676;
$default-text-color: #86939e;
$brand-border2: #e1e8ee;

$white: #fff;
$color-1: #4ea9e0; // blue dark
$color-2: #5e6977; // red
$color-5: #f5f8fa; // border offwhtie
$color-6: #43484d;
$color-7: #eeeeee; //border colors
$color-9: #F6F8FA;
$color-10: #fff; // primary color hyerlinks
$color-11: #26374A;
$hero_bg: #f5f8fa;
// fonts 
$primary-font: "Roboto", sans-serif,
serif;
$secondary-font: 'Open Sans',
sans-serif;
$font-weight-100: 100;



$brand-border: #e1e8ee; // previews borders
$brand-border-dark: #30384b; // previews borders
$brand-bg: #F5F8FA; // previews borders

//Bootstrap Overide

$blue: #03a9f4;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ED5564;
$orange: #fd7e14;
$yellow: #FCCE54;
$green: #7dc855;
$teal: #20c997;
$cyan: #78BCEE;
$dark: #272C33;
$border: rgba(225, 232, 238, 1);

$brand-primary: $blue !default; // #337ab7
$brand-success: $green !default;
$brand-info: $cyan !default;
$brand-warning: $yellow !default;
$brand-danger: $red !default;
$brand-border3: rgba(120, 130, 140, 0.13);

$table-hover-bg: $brand-bg;
$card-border-color : $border;
$headings-color:  $dark-heading-color;
$headings-font-weight: 300;
$h1-font-size : 2.0rem;
$h2-font-size : 1.6rem;
$h3-font-size : 1.4rem;
$h4-font-size : 1.2rem;
$h5-font-size : 1rem;
$h6-font-size : 0.875rem;
$table-accent-bg:$brand-bg;
$table-border-color: $border;
$list-group-border-color: $border;
$pagination-border-color:$border;
$list-group-action-color: $default-text-color;

//Template Variables

$hover-shadow :0 3px 9px 0 rgba(0, 0, 0, 0.26);
$shadow1 : 0 3px 9px 0 rgba(0, 0, 0, 0.26);
$shadow2 : 0 0 25px 0 #e5f0fa;
$shadow : 0 0 6px rgba(0,0,0,.1);


// LAYOUT
// --------------------------------------------------------
// Side bar and logo width
$sidebar-width: 330px;
// Boxed layout maximum width
$boxed-layout-max-width: 1024px;
// When the logo should go to the top of the screen
//$screen-header-collapse: $screen-xs-max;

// Link colors (Aka: <a> tags)
//$link-color: $light-blue;
//$link-hover-color: lighten($link-color, 15%);

// Body background (Affects main content background only)
$body-bg: #ecf0f5; // DEPRECATED
$body-dark:#212121;
$content-bg: #ecf0f5;



// SIDEBAR SKINS
// --------------------------------------------------------
// Dark sidebar
$sidebar-dark-bg: $dark;
$sidebar-dark-hover-bg: rgba(0,0,0,0.1);
$sidebar-dark-color: lighten($sidebar-dark-bg, 60%);




// sidebar-expanded-on-hover
$sidebar-expanded-shadow: 3px 0 8px rgba(0,0,0,.125);


// CONTROL SIDEBAR
// --------------------------------------------------------
$control-sidebar-width: $sidebar-width;


// TRANSITIONS SETTINGS
// --------------------------------------------------------

// Transition global options
$transition-speed: 3s;
$transition-fn: ease-in-out;
$transition-sidebar-right :right $transition-speed $transition-fn;