//Plugins Override

//Dropzone
.dropzone{
  border: 2px dashed $brand-border;
}
//color Picker
.colorpicker{
  border: 1px solid $brand-border;
}

//switch
.material-switch > {
  input[type="checkbox"] {
    display: none;
  }
  label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
    &::before {
      background: rgb(0, 0, 0);
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      content: '';
      height: 16px;
      margin-top: -8px;
      position: absolute;
      opacity: 0.3;
      transition: all 0.4s ease-in-out;
      width: 40px;
    }
    &::after {
      background: rgb(255, 255, 255);
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      content: '';
      height: 24px;
      left: -4px;
      margin-top: -8px;
      position: absolute;
      top: -4px;
      transition: all 0.3s ease-in-out;
      width: 24px;
    }
  }
  input[type="checkbox"]:checked + label {
    &::before {
      background: inherit;
      opacity: 0.5;
    }
    &::after {
      background: inherit;
      left: 20px;
    }
  }
}