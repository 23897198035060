.counter > div {
  background: #fff;
  display: inline-block;
  height: 100px;
  padding: 20px 0;
  text-align: center;
  vertical-align: middle;
  width: 100px;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
  span:first-child {
    font-size: 28px;
    display: block;
  }
}
