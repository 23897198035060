.bootstrap-tagsinput {
  background-color: #fff;
  display: inline-block;
  padding: 10px 6px;
  margin-bottom: 10px;
  color: #555;
  vertical-align: middle;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
  border: 1px solid #e1e8ee;
  box-shadow: none;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  font-weight: 300;
  min-height: 3rem;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .tag {
    margin-right: 2px;
    color: white;
    border-radius: 20px;

    [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;
      &:after {
        content: "x";
        padding: 0 2px;
      }
      &:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }
}
