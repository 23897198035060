.mailbox-attachments li {
  float:left;
  width:200px;
  border:1px solid $brand-border;
  margin-bottom:10px;
  margin-right:10px;
  border-radius: 2px;
  .mailbox-attachment-name {
    color: $default-text-color;
    font-weight:400;
    font-size: 12px;
  }
  .mailbox-attachment-icon,.mailbox-attachment-info,.mailbox-attachment-size {
    display:block
  }
  .mailbox-attachment-info {
    padding:10px;
    background:$brand-bg;

  }
  .mailbox-attachment-size {
    font-size:12px
  }
  .mailbox-attachment-icon {
    text-align:center;
    font-size:65px;
    color:#666;
    padding:20px 10px
  }
  .mailbox-attachment-icon.has-img {
    padding:0
  }
  .mailbox-attachment-icon.has-img>img {
    max-width:100%;
    height:auto
  }
}