.box{
  text-align: center;
  a{
    color: $default-text-color;
    font-size: 12px;
    &:hover{
     font-weight: 400;
    }
  }
}

